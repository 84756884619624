<template>
  <div class="card">
    <overlay
      :show="loading"
      class-name="p-card-form"
    >
      <validation-observer ref="formItems">
        <b-form>
          <b-row>
            <!-- CPF -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="CPF*"
                label-for="cpf"
              >
                <validation-provider
                  #default="{ errors }"
                  name="CPF"
                  rules="required|cpf"
                >
                  <b-form-input
                    id="cpf"
                    v-model="getFormData.cpf"
                    v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00"
                    autocomplete="off"
                    @close="returnPessoa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Nome -->
            <b-col
              sm="6"
              lg="8"
              class="mb-2"
            >
              <b-form-group
                label="Nome completo*"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome completo"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="getFormData.nome"
                    placeholder="John Doe"
                    autocomplete="off"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- E-mail -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="E-mail*"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="E-mail"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="getFormData.email"
                    placeholder="johndoe@gmail.com"
                    autocomplete="off"
                    type="email"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Sexo -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="Sexo*"
                label-for="sexo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Sexo"
                  rules="required"
                >
                  <v-select
                    id="sexo"
                    v-model="getFormData.sexo"
                    :options="sex"
                    variant="custom"
                    item-text="name"
                    item-value="name"
                    placeholder="Selecione uma opção"
                    label="name"
                  >
                    <template v-slot:no-options>
                      Nenhum registro encontrado.
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Data de nascimento -->
            <b-col
              sm="6"
              lg="4"
              class="mb-2"
            >
              <b-form-group
                label="Data de nascimento*"
                label-for="nascimento"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data de nascimento"
                  rules="required|nascimento"
                >
                  <cleave
                    id="nascimento"
                    v-model="getFormData.nascimento"
                    class="form-control"
                    :raw="false"
                    :options="options.date"
                    autocomplete="off"
                    placeholder="00/00/0000"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Perfil, Departamento, Regiões, Unidades -->
          <company-profile-settings
            ref="profileSettings"
            :mode="getMode"
          />

          <table-companies
            ref="tableCompanies"
            :mode="getMode"
            :username="getFormData.nome"
            :companies-in-update="getFormData.empresas"
            :groups-in-update="getFormData.grupos"
            @setCompanies="setCompanies"
            @setGroups="setGroups"
          />

          <b-row>
            <b-col
              cols="12"
              class="mt-3"
            >
              <button
                type="button"
                class="btn custom-blue button-custom-size"
                @click="formSubmit(true)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar usuário
              </button>

              <button
                v-if="getMode === 'insert'"
                type="button"
                class="btn custom-blue button-custom-size"
                @click="formSubmit(false)"
              >
                <feather-icon icon="CheckIcon" />
                Salvar e cadastrar novo
              </button>

              <button
                type="button"
                class="btn btn-outline-danger button-custom-size"
                @click="cancel"
              >
                <feather-icon icon="XIcon" />
                Cancelar
              </button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </overlay>
  </div>
</template>

<script>
import {
  BCol, BForm, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { cpf, email, required } from '@validations'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { getArrayAttr, strClear } from '@core/utils/utils'
import moment from 'moment'
import TableCompanies from '@/views/custom-pages/gerenciarUsuarios/components/TableCompanies.vue'
import {
  createCompanyUser,
  updateCompanyUser,
} from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import {
  successMessage,
  warningInfoMessage,
  warningMessage,
  warningMessageChangeProfileCase1,
} from '@/libs/sweetalerts'
import CompanyProfileSettings from '@/views/custom-pages/gerenciarUsuarios/components/CompanyProfileSettings.vue'
import chavePerfil from '@/enum/chavePerfil'

export default {
  components: {
    CompanyProfileSettings,
    Overlay,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    TableCompanies,
    Cleave,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
  },

  props: {
    mode: {
      type: String,
      default: 'insert',
    },
  },

  data() {
    return {
      required,
      email,
      cpf,

      moment,

      titlePage: '',

      loading: false,

      redirect: false,

      sex: [{ name: 'Masculino' }, { name: 'Feminino' }],

      options: {
        date: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
      },

      regions: [],
      chooseRegions: [],
    }
  },

  computed: {
    getMode() {
      return this.mode
    },

    getFormData() {
      return this.$store.getters['companyUserState/getFormData']
    },

    getProfileKey() {
      return this.getFormData.perfil ? this.getFormData.perfil.chave : null
    },

    getUoProfileMode() {
      return [
        chavePerfil.SAUDE_REGIAO,
        chavePerfil.ADMIN_UNIDADE,
        chavePerfil.VACINADOR_PADRAO,
        chavePerfil.VACINADOR_ADMIN,
        chavePerfil.SAUDE_UO,
      ].includes(this.getProfileKey)
    },

    hasProfileChange() {
      return (
        this.getProfileKey && this.getProfileKey !== chavePerfil.ADMIN_EMPRESA
      )
    },

    noCompanies() {
      return this.getFormData.empresas.length === 0
    },

    noUnits() {
      return this.getFormData.unidades.length === 0
    },

    requiredCompanyRules() {
      return (
        this.getProfileKey === chavePerfil.ADMIN_EMPRESA
        || this.getMode === 'insert'
      )
    },
  },

  methods: {
    async formSubmit(redirect) {
      this.redirect = redirect

      const resultFormItems = new Promise((resolve, reject) => {
        this.$refs.formItems
          .validate()
          .then(success => {
            success ? resolve(true) : resolve(false)
          })
          .catch(() => {
            reject()
          })
      })

      const resultProfileSettings = new Promise((resolve, reject) => {
        this.$refs.profileSettings.$refs.formProfileSettings
          .validate()
          .then(success => {
            success ? resolve(true) : resolve(false)
          })
          .catch(() => {
            reject()
          })
      })

      if ((await resultFormItems) && (await resultProfileSettings)) {
        if (this.getUoProfileMode && this.noUnits) {
          await warningMessage(
            'Ao menos uma unidade deve ser vinculada ao usuário.',
          )

          return
        }

        if (this.noCompanies && this.requiredCompanyRules) {
          await warningMessage(
            'Não é possível cadastar/editar o usuário sem vínculo com empresa(s).',
          )

          return
        }

        if (this.hasProfileChange) {
          warningMessageChangeProfileCase1(
            this.getFormData.nome,
            this.getFormData.perfil.descricao,
          ).then(() => this.core())
        } else {
          await this.core()
        }
      }
    },
    returnPessoa() {
      if(this.getMode != 'insert') {
        return;
      }

      if (this.getFormData.cpf.length === 14) {
        this.$http.get(this.$api.getPessoaFisicaPorCpf(strClear(this.getFormData.cpf))).then(data => {
          const pessoa = data.data
          if (data.status === 200) {
            warningInfoMessage('CPF já existe em nossa base', 'Confira os dados e depois salve o colaborador', 'Entendido')
            this.getFormData.nome = pessoa.nome
            this.getFormData.sexo = {name: pessoa.genero}
            this.getFormData.nascimento = moment(pessoa.data_nascimento).format('DD/MM/YYYY')
          }else{
            this.getFormData.nome = null
            this.getFormData.sexo = null
            this.getFormData.nascimento = null
          }
        });
      }else{
        this.getFormData.nome = null
        this.getFormData.sexo = null
        this.getFormData.nascimento = null
      }
    },

    async core() {
      if (this.getMode === 'insert') {
        await this.handleCreate()
      }

      if (this.getMode === 'update') {
        await this.handleUpdate()
      }

      return true
    },

    async handleCreate() {
      this.loading = true

      const formData = this.formDataGenerate()

      await createCompanyUser(formData)
        .then(response => {
          if (response.status === 201) {
            this.clear()

            successMessage('O registro foi salvo com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    async handleUpdate() {
      if (this.getUoProfileMode && this.getFormData.unidades.length === 0) {
        await warningMessage(
          'Ao menos uma unidade deve ser vinculada ao usuário.',
        )

        return
      }

      this.loading = true

      const { id } = this.getFormData

      const formData = this.formDataGenerate()

      await updateCompanyUser(id, formData)
        .then(response => {
          if (response.status === 200) {
            this.clear()

            successMessage('O registro foi alterado com sucesso!')
          }
        })
        .catch(error => {
          this.handleError(error.response)
        })

      this.loading = false
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage(
        'Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.',
      )
    },

    setCompanies(companies) {
      this.$store.commit('companyUserState/setCompanies', companies)
    },

    setGroups(groups) {
      this.$store.commit('companyUserState/setGroups', groups)
    },

    formDataGenerate() {
      return {
        nome: this.getFormData.nome,
        cpf: strClear(this.getFormData.cpf),
        dataNascimento: moment(
          this.getFormData.nascimento,
          'DD-MM-YYYY',
        ).format('YYYY-MM-DD'),
        sexo: this.getFormData.sexo.name,
        email: this.getFormData.email,
        perfil: this.getFormData.perfil
          ? this.getFormData.perfil.id_perfil
          : null,
        departamento: this.getFormData.departamento
          ? this.getFormData.departamento.id_departamento
          : null,
        empresasId: this.getFormData.empresas
          ? getArrayAttr(this.getFormData.empresas, 'id_empresa')
          : [],
        unidades: this.getFormData.unidades || [],
        nomeMae: this.getFormData.nomeMae
          ? this.getFormData.nomeMae
          : null,
        idNacionalidade: this.getFormData.idNacionalidade
          ? this.getFormData.idNacionalidade
          : null,
        gruposId: this.getFormData.grupos
          ? getArrayAttr(this.getFormData.grupos, 'id_grupo')
          : [],
      }
    },

    redirectToList() {
      if (this.redirect) {
        this.$router.replace({
          name: 'usuario-empresa-list',
        })
      }
    },

    cancel() {
      if (this.getMode === 'insert') {
        this.redirect = true
        this.clear()
      } else {
        this.$router.replace({
          name: 'usuario-empresa-list',
        })
      }
    },

    clear() {
      this.$store.commit('companyUserState/clearFormData')

      this.$store.commit('companyUserState/setCompanyUserUpdate', null)
      this.$store.commit('companyUserState/setCompanyUserView', null)

      this.$refs.tableCompanies.companies = []
      this.$refs.tableCompanies.groups = []
      this.$refs.tableCompanies.showTable = false
      this.$refs.tableCompanies.clear()
      this.$refs.tableCompanies.clearAutoSuggest()

      this.$refs.formItems.reset()

      this.redirectToList()
    },
  },
  watch: {
    'getFormData.cpf': {
      handler(novoCpf, cpfAnterior) {
        if (novoCpf && novoCpf.length === 14 && strClear(novoCpf) !== strClear(cpfAnterior)) {
          this.returnPessoa();
        }
      },
      immediate: false
    }
  },
}
</script>

<style lang="scss" scoped>
.p-card-form {
  padding: 3rem 2rem;
}

.button-custom-size {
  margin-right: 2rem;
}

@media (max-width: 705px) {
  .button-custom-size {
    margin-bottom: 1rem;
  }
}

@media (max-width: 400px) {
  .button-custom-size {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
