<template>
  <overlay
      :show="loading"
      :class-name="className"
  >
    <b-form>
      <b-row v-if="canTipoAtuacaoGrupoEmpresa">
        <b-col sm="6" lg="4">
          <b-form-group
              label-for="grupoEmpresas"
          >
            <template #label>
              <span class="custom-label">Grupo de Empresas: </span>
              <span>
                <feather-icon
                    size="12"
                    icon="HelpCircleIcon"
                    class="text-custom-blue"
                    v-b-tooltip.hover.top="'Grupo de Empresas é um campo de pesquisa utilizado apenas para filtrar e incluir apenas empresas de Base Nacional.'"
                />
              </span>
            </template>
            <v-select
                id="grupoEmpresas"
                v-model="search.group"
                :options="groups"
                label="descricao"
                placeholder="Selecione um grupo de empresas"
            >
              <span slot="no-options">Nenhum registro encontrado.</span>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col sm="6" lg="8">
          <button
              type="button"
              class="btn btn-outline-primary form-inline-mt mr-2"
              :disabled="disableButtons || naoSelecionouGrupo"
              @click="formSubmitGroup()"
          >
            <feather-icon icon="PlusIcon" />
            Vincular empresa(s)
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
            sm="6"
            lg="4"
        >
          <b-form-group
              label="CPF ou CNPJ*"
              label-for="cpfCnpj"
          >
            <the-mask
                id="cpfCnpj"
                ref="inputMask"
                v-model="search.document"
                class="form-control"
                :mask="['###.###.###-##', '##.###.###/####-##']"
                placeholder="00.000.000/0000-00"
            />

            <small
                v-if="!getValidatorCpfCnpj && search.document.length > 0"
                class="text-danger"
            >
              O campo CPF ou CNPJ é inválido
            </small>
          </b-form-group>
        </b-col>

        <b-col
            sm="6"
            lg="4"
        >
          <companies-auto-suggest
              ref="nameAutoSuggest"
              @focus="searchEmptyError = false"
              @setCompanyName="setCompanyName"
          />
        </b-col>

        <b-col
            sm="12"
            lg="6"
            xl="4"
        >
          <button
              type="button"
              class="btn btn-outline-primary form-inline-mt mr-2"
              :disabled="disableButtons || !getValidatorCpfCnpj"
              @click="formSubmit()"
          >
            <feather-icon icon="PlusIcon" />
            Vincular empresa
          </button>

          <button
              v-if="search.companyName || search.document"
              type="button"
              class="btn custom-outline-warning form-inline-mt"
              :disabled="disableButtons"
              @click="
              clearAutoSuggest();
              clearFilters();
            "
          >
            <feather-icon icon="XIcon" />
            Limpar
          </button>
        </b-col>
      </b-row>
    </b-form>

    <b-row>
      <b-col cols="12">
        <b-alert
            variant="primary"
            :show="dismissCountDownAlerts"
            class="mx-2 mt-5"
            @dismissed="dismissCountDownAlerts = 0"
            @dismiss-count-down="countDownChangedAlerts"
        >
          <div class="alert-body d-flex justify-content-center">
            <span class="text-primary">
              <strong class="text-primary">
                Favor adicionar o usuário pela aba Usuários da visualização de empresa
              </strong>
            </span>
          </div>
        </b-alert>

        <b-alert
            variant="primary"
            :show="tableError"
            class="mx-2 mt-5"
        >
          <div class="alert-body d-flex justify-content-center">
            <span class="text-primary">
              <strong class="text-primary">
                Sistema de busca indisponível no momento.
              </strong>
            </span>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div
            v-if="loadingTable"
            class="spinner-area"
        >
          <b-spinner class="spinner-border text-custom-blue" />
        </div>
      </b-col>

      <b-col
          v-if="!loadingTable"
          cols="12"
          class="my-2"
      >
        <div class="b-table-sticky-header table-responsive">
          <b-alert
              v-model="groupAlreadyExistsAlert"
              v-height-fade.appear
              variant="warning"
              dismissible
              class="mb-0"
          >
            <div class="alert-body">
              Este grupo já existe na lista de vínculos com o usuário.
            </div>
          </b-alert>

          <b-alert
              v-model="groupWithCompaniesAlert"
              v-height-fade.appear
              variant="warning"
              dismissible
              class="mb-0"
          >
            <div class="alert-body">
              Este grupo não possui empresas vinculadas ao seu departamento.
            </div>
          </b-alert>

          <div class="custom-header mt-1">
            <div class="d-flex">
              <b-form-checkbox
                  v-model="checkAllGroup"
                  :disabled="groupsUser.length === 0"
                  @change="checkOrUncheckAllGroups()"
              />
              <h4>Grupos vinculados</h4>
            </div>

            <button
                type="button"
                class="btn btn-clear btn-disable-all"
                :disabled="groupsToRemove.length === 0"
                @click="handleConfirmRemoveGroup(true)"
            >
              <feather-icon icon="XIcon" />
              DESVINCULAR TODOS
            </button>
          </div>

          <b-col
              cols="12"
              class="text-center"
          >
            <p
                v-if="groupsUser.length === 0"
                class="table-empty"
            >
              O usuário ainda não possui nenhum grupo vinculado.
            </p>
          </b-col>

          <table class="table">
            <caption class="d-none">Grupos vinculados</caption>
            <thead class="d-none">
              <tr>
                <th>Nome grupo</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
            <tr
                v-for="(group, index) in groupsUser"
                :key="index"
            >
              <td>
                <div class="d-flex">
                  <b-form-checkbox
                      v-model="groupsToRemove"
                      :value="group.id_grupo"
                      @change="checkGroupCompanies(group.id_grupo)"
                  />

                  <div class="d-flex flex-column">
                      <span>
                        {{ group.descricao }} <b>{{ loadGroupCompaniesQuantity ? returnCompaniesTotal(group.id_grupo) : " "}} </b>
                      </span>
                  </div>
                </div>
              </td>

              <td>
                <button
                    type="button"
                    class="btn btn-clear btn-disable-one"
                    @click="
                      handleConfirmRemoveGroup(false, group.id_grupo)
                    "
                >
                  <feather-icon icon="XIcon" />
                  DESVINCULAR
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      
      <b-col
          v-if="!loadingTable"
          cols="12"
          class="my-2"
      >
        <div class="b-table-sticky-header table-responsive">
          <b-alert
              v-model="companyAlreadyExistsAlert"
              v-height-fade.appear
              variant="warning"
              dismissible
              class="mb-0"
          >
            <div class="alert-body">
              Esta empresa já existe na lista de vínculos com o usuário.
            </div>
          </b-alert>

          <b-alert
              v-model="groupWithCompaniesAlert"
              v-height-fade.appear
              variant="warning"
              dismissible
              class="mb-0"
          >
            <div class="alert-body">
              Este grupo não possui empresas vinculadas ao seu departamento.
            </div>
          </b-alert>

          <div class="custom-header mt-1">
            <div class="d-flex">
              <b-form-checkbox
                  v-model="checkAll"
                  :disabled="companiesUser.length === 0"
                  @change="checkOrUncheckAll()"
              />
              <h4>Empresas vinculadas</h4>
            </div>

            <button
                type="button"
                class="btn btn-clear btn-disable-all"
                :disabled="companiesToRemove.length === 0"
                @click="handleConfirmRemoveCompany(true)"
            >
              <feather-icon icon="XIcon" />
              DESVINCULAR TODOS
            </button>
          </div>

          <b-col
              cols="12"
              class="text-center"
          >
            <p
                v-if="companiesUser.length === 0"
                class="table-empty"
            >
              O usuário ainda não possui nenhuma empresa vinculada.
            </p>
          </b-col>

          <table class="table">
            <caption class="d-none">Empresas vinculadas</caption>
            <thead class="d-none">
              <tr>
                <th>Nome empresa</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
            <tr
                v-for="(company, index) in companiesUser"
                :key="index"
            >
              <td>
                <div class="d-flex">
                  <b-form-checkbox
                      v-model="companiesToRemove"
                      :value="company.id_empresa"
                  />

                  <div class="d-flex flex-column">
                      <span>
                        {{ company.nome_empresa }}
                      </span>

                    <div class="mt-1">
                        <span>
                          <strong>CNPJ|CPF:</strong>
                          {{
                            company.documento
                                | VMask(
                                company.documento.length <= 11
                                    ? "###.###.###-##"
                                    : "##.###.###/####-##"
                                )
                          }}
                        </span>

                      <span class="pipe">|</span>

                      <span>
                          <strong>Cidade:</strong>
                          {{
                          company.empresa_endereco[0].cidade
                              ? company.empresa_endereco[0].cidade.nome
                              : "-"
                        }}
                        </span>

                      <span class="pipe">|</span>

                      <span>
                          <strong>UF:</strong>
                          {{
                          company.empresa_endereco[0].cidade
                              ? company.empresa_endereco[0].cidade.uf
                              : "-"
                        }}
                        </span>
                    </div>
                  </div>

                </div>
              </td>

              <td>
                <button
                    type="button"
                    class="btn btn-clear btn-disable-one"
                    @click="
                      handleConfirmRemoveCompany(false, company.id_empresa)
                    "
                >
                  <feather-icon icon="XIcon" />
                  DESVINCULAR
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </overlay>
</template>

<script>
import { required, cpfCnpj } from '@validations'
import {
  BAlert,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BIcon,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { TheMask, mask } from 'vue-the-mask'
import { heightFade } from '@core/directives/animations'
import { getArrayAttr } from '@core/utils/utils'
import { validatorCpfCnpj } from '@core/utils/validations/validators'
import {getCompaniesFromGroup, getGroups, getLinkedUserCompanies, getGroupCompaniesQuantity} from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import CompaniesAutoSuggest from '@/views/custom-pages/gerenciarUsuarios/components/CompaniesAutoSuggest.vue'
import { warningMessageRemoveCompany } from '@/libs/sweetalerts'
import vSelect from 'vue-select'
import {actions, subjects} from "@/libs/acl/rules";

export default {
  components: {
    BFormCheckbox,
    BAlert,
    BSpinner,
    BFormGroup,
    BForm,
    BCol,
    BRow,
    TheMask,
    Overlay,
    CompaniesAutoSuggest,
    vSelect,
    BIcon,
  },

  directives: {
    mask,
    'height-fade': heightFade,
  },
  props: {
    mode: {
      type: String,
      default: 'insert',
    },
    className: {
      type: String,
      default: '',
    },
    username: {
      type: String,
      default: '',
    },
    companiesInUpdate: {
      type: Array,
      default: () => [],
    },
    groupsInUpdate: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      required,
      cpfCnpj,

      dismissSecsAlerts: 3,
      dismissCountDownAlerts: 0,

      loading: false,
      loadingTable: false,

      disableButtons: false,

      searchEmptyError: false,

      checkAll: false,
      checkAllGroup: false,

      companyAlreadyExistsAlert: false,
      groupAlreadyExistsAlert: false,
      groupWithCompaniesAlert: false,

      search: {
        document: '',
        companyName: '',
        usuarioId: '',
        group: '',
      },

      companiesToRemove: [],
      groupsToRemove: [],

      companiesUser: [],
      groupsUser: [],

      tableError: false,

      buttonDisabled: false,

      groups: [],
      canTipoAtuacaoGrupoEmpresa: false,

      groupCompaniesSelection: {},
      groupCompaniesQuantity: [],
      loadGroupCompaniesQuantity: false
    }
  },

  computed: {
    getMode() {
      return this.mode
    },

    getUsername() {
      return this.username
    },

    getCompaniesInUpdate() {
      return this.companiesInUpdate
    },

    getGroupsInUpdate() {
      return this.groupsInUpdate
    },

    getSearch() {
      return this.search
    },

    disableAddCompany() {
      return !this.getSearch.companyName && !this.getSearch.document
    },

    getValidatorCpfCnpj() {
      return validatorCpfCnpj(this.search.document)
    },

    naoSelecionouGrupo() {
      return this.search.group === '' || this.search.group === null
    }
  },

  watch: {
    companiesToRemove(value) {
      if (value.length === 0) {
        this.checkAll = false
      }
    },

    groupsToRemove(value) {
      if (value.length === 0) {
        this.checkAllGroup = false
      }
    },

    companiesUser: {
      handler(value) {
        if (value.length === 0) {
          this.companiesToRemove = []

          this.tableError = false
          this.checkAll = false
        }
      },
      deep: true,
    },

    groupsUser: {
      handler(value) {
        if (value.length === 0) {
          this.groupsToRemove = []

          this.tableError = false
          this.checkAllGroup = false
        }
      },
      deep: true,
    },

    getSearch: {
      handler(value) {
        return value
      },
      deep: true,
    },
  },

  mounted() {
    if (this.getMode === 'update') {
      this.companiesUser = this.getCompaniesInUpdate
      this.groupsUser = this.getGroupsInUpdate
    }
    this.canTipoAtuacaoGrupoEmpresa = this.$can(actions.VISUALIZAR, subjects.TIPO_ATUACAO_GRUPO_EMPRESA);

    if (this.canTipoAtuacaoGrupoEmpresa) {
      this.setGruposEmpresa()
      this.getGroupCompaniesQuantity()
    }
  },

  methods: {
    async formSubmit() {
      if (!this.disableAddCompany) {
        if (this.getValidatorCpfCnpj) {
          await this.findAndPopulateCompaniesList()
        }
      }
    },

    async formSubmitGroup() {
        if (!this.naoSelecionouGrupo) {
          await this.findAndPopulateCompaniesFromGroupList()          
        }
    },

    addCompany(company) {
      const empresaNaoAdicionada = this.companiesUser
          .findIndex(companyUser => companyUser.id_empresa === company.id_empresa) === -1;

      if (empresaNaoAdicionada) {
        this.companiesUser.push({
          documento: company.documento,
          empresa_endereco: company.empresa_endereco,
          id_empresa: company.id_empresa,
          nome_empresa: company.nome_empresa,
          id_grupo: company.id_grupo
        })
      }
    },

    addGroup(group) {
      const grupoNaoAdicionado = this.groupsUser
          .findIndex(groupUser => groupUser.id_grupo === group.id_grupo) === -1;

      if (grupoNaoAdicionado) {
        this.groupsUser.push({
          id_grupo: group.id_grupo,
          descricao: group.descricao,
        })

        this.getGroupCompaniesQuantity();
      }else{
        this.groupAlreadyExistsAlert = true
      }
    },

    async findAndPopulateCompaniesList() {
      const exists = this.companiesUser.filter(
          val => val.documento === this.search.document,
      )

      if (exists.length > 0) {
        this.companyAlreadyExistsAlert = true

        return false
      }

      this.loadingTable = true
      this.disableButtons = true
      this.groupWithCompaniesAlert = false
      this.tableError = false

      await getLinkedUserCompanies(this.getParams())
          .then(response => {
            if (response.status === 200) {
              const { data } = response.data

              if (data.length > 0) {
                data.forEach(company => {
                  this.addCompany(company)
                })

                this.clearFilters()
                this.clearAutoSuggest()

                this.companyAlreadyExistsAlert = false

                this.setCompanies()
              } else {
                this.dismissCountDownAlerts = this.dismissSecsAlerts
              }
            }
          })
          .catch(() => {
            this.tableError = true
          })

      this.loadingTable = false
      this.disableButtons = false

      return true
    },

    async findAndPopulateCompaniesFromGroupList() {
      const params = {
          colunaOrdenar: 'nome_empresa',
          ordem: 'asc',
          tamanho: 1000,
          pagina: 1,
          paginacao: true,
          id_grupo: this.search.group.id_grupo,
      }
      this.loadingTable = true
      this.disableButtons = true
      this.groupWithCompaniesAlert = false
      this.tableError = false;

      this.addGroup(this.search.group);
      this.setGroups()

      await getCompaniesFromGroup(params)
          .then(response => {
            if (response.status === 200) {
              const { data } = response.data

              this.groupWithCompaniesAlert = data.length === 0
              data.forEach(company => {
                this.addCompany(company)
              })
            }

            this.search.group = ''
            this.setCompanies();
          })
          .catch(() => {
            this.tableError = true
          })

      this.loadingTable = false
      this.disableButtons = false

      return true
    },

    setCompanyName({ companyNameSuggest, cpfCnpjSuggest }) {
      this.search.companyName = companyNameSuggest
      this.search.document = cpfCnpjSuggest
    },

    handleConfirmRemoveCompany(removeMany, company) {
      warningMessageRemoveCompany(this.getUsername).then(() => {
        if (removeMany) {
          return this.removeManyCompanies()
        }

        return this.removeCompany(company)
      })
    },

    handleConfirmRemoveGroup(removeMany, group) {
      if (removeMany) {
        return this.removeManyGroups()        
      }
      
      return this.removeGroup(group)
    },

    checkGroupCompanies(groupId) {
      const isMarked = this.groupsToRemove.includes(groupId)
      const companiesInGroup = this.companiesUser.filter(company => company.id_grupo === groupId);

      if (isMarked) {
        companiesInGroup.forEach(company => {
          if (!this.companiesToRemove.includes(company.id_empresa)) {
            this.companiesToRemove.push(company.id_empresa);
          }
        });
        this.groupCompaniesSelection[groupId] = companiesInGroup.map(company => company.id_empresa);
      } else {
          this.companiesToRemove = this.companiesToRemove.filter(companyId => !companiesInGroup.some(company => company.id_empresa === companyId));
          this.groupCompaniesSelection[groupId] = [];
      }
      
    },

    removeManyGroups() {
      this.groupsToRemove.forEach(groupId => {
        this.groupsUser = this.groupsUser.filter(i => i.id_grupo !== groupId)

        let companiesToRemove = [];
        const groupCompanies = this.companiesUser.filter(company => company.id_grupo === groupId);

        groupCompanies.forEach(company => {
          if (this.companiesToRemove.includes(company.id_empresa)) {
            companiesToRemove.push(company.id_empresa);
          }
        });


        this.companiesUser = this.companiesUser.filter(company => !companiesToRemove.includes(company.id_empresa));
      })

      this.setGroups()
      this.setCompanies()
    },

    removeGroup(groupId) {
      this.groupsUser = this.groupsUser.filter(i => i.id_grupo !== groupId)

      if (this.companiesToRemove.length === 0) {
            this.companiesToRemove = this.companiesUser
              .filter(company => company.id_grupo === groupId)
              .map(company => company.id_empresa); 
      } 
        
      this.companiesUser = this.companiesUser.filter(company => 
        !this.companiesToRemove.includes(company.id_empresa)
      );

      this.setGroups()
      this.setCompanies()
    },

    removeCompany(value) {
      this.companiesUser = this.companiesUser.filter(
          i => i.id_empresa !== value,
      )

      this.setCompanies()
    },

    removeManyCompanies() {
      this.companiesToRemove.forEach(item => {
        this.companiesUser = this.companiesUser.filter(
            i => i.id_empresa !== item,
        )
      })

      this.setCompanies()

      this.checkAll = false
    },

    checkOrUncheckAll() {
      let companies = this.companiesUser

      if (this.checkAll) {
        companies = companies.filter(item => item.id_empresa)

        this.companiesToRemove = getArrayAttr(companies, 'id_empresa')
      } else {
        this.companiesToRemove = []
      }
    },

    checkOrUncheckAllGroups() {
      let groups = this.groupsUser

      if (this.checkAllGroup) {
        groups = groups.filter(item => item.id_grupo)

        this.groupsToRemove = getArrayAttr(groups, 'id_grupo')
        groups.forEach(group => {this.checkGroupCompanies(group.id_grupo)});

      } else {
        this.groupsToRemove = []
        this.companiesToRemove = []
      }
    },

    setCompanies() {
      if (this.companiesUser.length > 0) {
        this.companiesUser.sort((a, b) => {
          if (a.nome_empresa.toLowerCase() < b.nome_empresa.toLowerCase()) {
            return -1
          }
          return true
        })
      }

      this.$emit('setCompanies', this.companiesUser)
    },
    setGroups() {
      if (this.groupsUser.length > 0) {
        this.groupsUser.sort((a, b) => {
          if (a.descricao.toLowerCase() < b.descricao.toLowerCase()) {
            return -1
          }
          return true
        })
      }

      this.$emit('setGroups', this.groupsUser)
    },

    getParams() {
      return {
        porPagina: 1000,
        pagina: 1,
        cpfCnpj: this.getSearch.document,
        nomeEmpresa: this.getSearch.nomeEmpresa,
        usuarioId: this.getSearch.usuarioId,
      }
    },

    clear() {
      this.companiesUser = []
      this.companiesToRemove = []
      this.groupsUser = []
      this.groupsToRemove = []

      this.tableError = false
      this.checkAll = false
      this.checkAllGroup = false

      this.search.document = ''
      this.search.companyName = ''
    },

    clearAutoSuggest() {
      this.$refs.nameAutoSuggest.chooseCompany = ''
      this.$refs.nameAutoSuggest.results = []
      this.$refs.nameAutoSuggest.result = []
      this.$refs.nameAutoSuggest.suggestions = []
      this.$refs.nameAutoSuggest.selected = null
    },

    clearFilters() {
      this.search.document = ''
      this.search.companyName = ''

      this.$refs.inputMask.lastValue = ''
      this.$refs.inputMask.display = ''
    },

    countDownChangedAlerts(dismissCountDown) {
      this.dismissCountDownAlerts = dismissCountDown
    },

    async setGruposEmpresa() {
      const params = {
          colunaNome:'descricao',
          colunaOrdem:'asc',
          todos: true
      }
      await getGroups(params)
          .then(response => {
            if (response.status === 200) {
              this.groups = response.data.map((grupo) => {
                return {
                  id_grupo: grupo.id_grupo,
                  descricao: grupo.descricao,
                }
              })
            }
          })
          .catch(() => {
            this.tableError = true
          })

      this.loadingTable = false
      this.disableButtons = false
    },

    async getGroupCompaniesQuantity() {   
      this.loadGroupCompaniesQuantity = false  
      const idsGrupos = this.groupsUser.map(group => group.id_grupo);

      if(idsGrupos.length > 0) {
        const params = {
          ids_grupo: idsGrupos,
        }

        await getGroupCompaniesQuantity(params)
            .then(response => {
              if (response.status === 200) {
                this.groupCompaniesQuantity = response.data.data.map((grupo) => {
                  return {
                    id_grupo: grupo.id_grupo,
                    descricao: grupo.descricao,
                    quantidade_de_empresas: grupo.quantidade_de_empresas
                  }
                })
              }
            })
            .catch(() => {
              this.tableError = true
            })

            this.loadGroupCompaniesQuantity = true
      }
    },

    returnCompaniesTotal(id_grupo) {
      const group = this.groupCompaniesQuantity.find(group => group.id_grupo === id_grupo);
      const companiesFromGroup = this.companiesUser.filter(company => company.id_grupo === id_grupo);
      const quantityCompaniesFromGroup = companiesFromGroup.length;
      return String(quantityCompaniesFromGroup + "/" + group?.quantidade_de_empresas)
    }
  },
}
</script>

<style scoped lang="scss">
@import "@/assets/scss/custom/table-companies.scss";
</style>
