export default {
  SUPORTE: 'SUPORTE',
  SAUDE_DN: 'SAUDE_DN',
  ADMIN_DN: 'ADMIN_DN',
  MERCADO_DN: 'MERCADO_DN',
  SAUDE_UO: 'SAUDE_UO',
  SAUDE_DR: 'SAUDE_DR',
  VACINADOR_ADMIN: 'VACINADOR_ADMIN',
  VACINADOR_PADRAO: 'VACINADOR_PADRAO',
  ADMIN_UNIDADE: 'ADMIN_UNIDADE',
  MERCADO_DR: 'MERCADO_DR',
  SAUDE_REGIAO: 'SAUDE_REGIAO',
  ADMIN_DR: 'ADMIN_DR',
  ADMIN_EMPRESA: 'ADMIN_EMPRESA',
  MASTER: 'MASTER',
}
